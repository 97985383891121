<template>
  <CPopover
    auto-close
    close-on-inner-click="false"
    panel-class="md:w-[300px] w-[220px] md:left-auto md:right-0 left-0"
  >
    <template #popoverButton>
      <slot name="agencyPlan" />
    </template>

    <template #popoverOptions>
      <div class="px-1 pt-0 pb-1">
        <div class="border border-grayCust-900 rounded-md px-3 py-5 space-y-3 bg-gradient">
          <span class="text-lg font-medium text-grayCust-700"> {{
            user.active_plan.is_custom_plan
              ? user.active_plan.base_plan.name
              : user.active_plan.name
          }}</span>
          <div class="flex items-center gap-2 md:flex-nowrap flex-wrap">
            <inertia-link
              v-if="user.active_plan.type != 'free' && !(user.active_plan.user_id && !user.active_plan.base_plan_id)"
              :href="route('subscription-plan.customize')"
            >
              <CButton
                icon-name="OutlineLightningBoltIcon"
                :btn-title="user.active_plan.number_of_addons ? $t('count_addons', { countAddons: user.active_plan.number_of_addons }) : $t('addons')"
                btn-type="gray-outline-btn"
              />
            </inertia-link>
            <inertia-link
              v-if="teamOwnerActivePlan &&
                teamOwnerActivePlan.name == 'Free'"
              :href="route('pricing', { trigger_ref: 'header_statistics_upgrade_plan' })"
            >
              <CButton
                icon-name="OutlineNewspaperIcon"
                :btn-title="$t('upgrade_plan')"
                btn-type="secondary"
              />
            </inertia-link>
            <inertia-link
              v-else
              :href="route('subscriptions.index')"
            >
              <CButton
                icon-name="OutlineNewspaperIcon"
                :btn-title="$t('subscription')"
                btn-type="secondary"
              />
            </inertia-link>
          </div>
        </div>
      </div>
      <div class="border-t border-grayCust-160 rounded-b-md">
        <div
          v-for="item in agencyPlanList"
          :key="item.name"
          class="px-4 py-2 space-y-1"
        >
          <div class="flex items-center justify-between">
            <span class="text-sm font-medium text-grayCust-640">{{ $t(item.name) }}</span>
            <span class="text-xs font-medium text-grayCust-640">{{ used(item).toFixed(item.decimal_point ?? 0) }}/{{ teamAllow[item.key] }}</span>
          </div>
          <ProgressBar
            :progress="usedPercentage(item.key, teamUsed, teamAllow)"
            :progress-bar-class="item.key === 'live_credits' || usedPercentage(
              item.key,teamUsed,teamAllow
            ) <= 80 ? 'bg-primary-900' : 'bg-redCust-900'"
          />
        </div>



        <!-- <div class="flex justify-center py-2.5">
          <CButton
            :btn-title="isAgencyPlanOpen ? 'View Less' : 'View More'"
            btn-type="gray-text-btn"
            :icon-name="isAgencyPlanOpen ? 'OutlineChevronUpIcon' : 'OutlineChevronDownIcon'"
            icon-position="last"
            @click="toggleAgencyPlan"
          />
        </div> -->
      </div>
    </template>
  </CPopover>
</template>

<script setup>
import CButton from "@/app/Common/CommonButton.vue";
import CPopover from '@/app/Common/CommonPopover.vue';
import ProgressBar from "@/app/Common/CommonProgressBar.vue";
import { featuresNumeric } from "@/features";
import { usedPercentage } from '@/helpers';
import { useAppStore } from '@/store/index';
import { computed, ref } from 'vue';

const isAgencyPlanOpen = ref(true); // Initially set to false to show "View More"

const {teamAllow, teamCan, teamUsed, user } = useAppStore();

defineEmits(["update:selectedOption"]);

const agencyPlanList = computed(() => {
  return isAgencyPlanOpen.value ? 
  featuresNumeric.filter((feature) => teamAllow[feature.key] && !feature.hide) : 
  featuresNumeric.filter((feature) => teamAllow[feature.key] && !feature.hide && teamCan[feature.key]).slice(0, 3);
});
// const users = computed(() => store.state.user);

const toggleAgencyPlan = () => {
  isAgencyPlanOpen.value = !isAgencyPlanOpen.value;
};

const used = (feature) => {
  return teamUsed[feature.key] !== undefined ? Math.min(Math.max(teamUsed[feature.key], 0), teamAllow[feature.key]) : 0;
};
</script>

<style scoped>
.bg-gradient {
  background: linear-gradient(180deg, rgba(235, 251, 255, 0) 0%, rgba(235, 251, 255, 1) 100%);
}
</style>