<script setup>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { nextTick, ref } from 'vue';

const props = defineProps({
  panelClass: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  popoverClass: {
    type: String,
    default: "",
  },
  buttonClass: {
    type: String,
    default: "",
  },
  autoClose: {
    type: Boolean,
    default: false,
  },
  positionBottom: {
    type: Boolean,
    default: false,
  },
});

const buttonRef = ref(null);
const panelRef = ref(null);
const popoverPosition = ref("bottom");

const openPopover = (event) => {
  nextTick(() => {
    setTimeout(() => checkPopoverPosition(event), 50); 
  });
};

const checkPopoverPosition = (event) => {
  if (props.positionBottom) {
    popoverPosition.value = "bottom";
    return;
  }

  nextTick(() => {
    const button = buttonRef.value?.$el;
    const panel = panelRef.value?.$el;

    if (button && panel) {
      const buttonRect = button.getBoundingClientRect();
      const panelHeight = panel.offsetHeight || 200;

      const spaceBelow = window.innerHeight - buttonRect.bottom;
      const spaceAbove = buttonRect.top;

      // If not enough space below but enough space above, open upwards
      if (spaceBelow < panelHeight && spaceAbove > panelHeight && event.pageY > 600) {
        popoverPosition.value = "top";
      } 
      // If enough space below or not enough space above, open downwards
      else {
        popoverPosition.value = "bottom";
      }
    }
  });
};

</script>

<template>
  <Popover
    v-slot="{ open, close }"
    class="relative"
    :class="props.popoverClass"
  >
    <PopoverButton
      ref="buttonRef"
      :class="[props.buttonClass, props.disabled ? 'opacity-50 cursor-not-allowed' : 'focus:outline-none headlessui-focus-button h-full']"
      :disabled="props.disabled"
      @click="openPopover"
    >
      <slot
        name="popoverButton"
        :open="open"
      />
    </PopoverButton>

    <transition
      enter-active-class="transition duration-300 ease-out"
      enter-from-class="transform -translate-y-2 opacity-0"
      enter-to-class="transform translate-y-0 opacity-100"
      leave-active-class="duration-100"
      leave-from-class="transform translate-y-0 opacity-100"
      leave-to-class="transform -translate-y-2 opacity-0"
    >
      <PopoverPanel
        ref="panelRef"
        class="absolute z-[10] bg-white rounded-lg shadow-md py-1 border border-gray-200 md:w-56 w-48 right-0"
        :class="[props.panelClass, popoverPosition === 'top' ? 'bottom-full mb-2' : 'mt-2']"
      >
        <div @click="props.autoClose ? close() : null"> 
          <slot name="popoverOptions" />
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
