<template>
  <div>
    <Header
      :title="dialogTitle ? dialogTitle : $t('time_to_upgrade')"
      icon-name="fire.svg"
    >
      <template
        v-if="!hideSubtitle"
        #subTitle
      >
        <span v-if="dialogSubtitle">{{ dialogSubtitle }}</span>
        <span v-else>{{ $t('you_have_requested_a_paid_feature') }} 
          <span class="font-bold">{{ $t(featureName) }}</span>. {{ $t('please_upgrade_to_continue') }}</span>
      </template>
    </Header>
    <div
      v-if="!user.is_team_owner"
      class="p-6"
    >
      <div class="text-center w-100">
        <h2 class="text-lg font-semibold text-grayCust-800">
          {{ $t('please_contact_team_owner_to_upgrade_plan') }}
        </h2>
      </div>
    </div>
    <div
      v-else
      class="p-6"
    >
      <!-- White label -->
      <div
        v-if="$page.props.TESTING_ENV"
        class="mb-6 space-y-6"
      >
        <Tab
          v-model:selected-tab="selectedPlanTab"
          :tab-list="tabList"
          btn-type="white"
          class="!bg-grayCust-160"
          text-color="!text-grayCust-1740"
        />
        <div class="space-y-4">
          <div
            class="flex flex-wrap items-center justify-between gap-3 rounded-lg border border-grayCust-75 bg-white p-[20px] shadow-sm"
          >
            <div class="flex items-center gap-3.5">
              <div
                class="flex size-10 items-center justify-center rounded-md border border-primary-550 bg-grayCust-50 p-2.5"
              >
                <img
                  :src="cdn('/images/UpgradeUserModal/white-label-icon.svg')"
                  alt=""
                >
              </div>
              <h2 class="text-lg font-semibold text-grayCust-800">
                Whitelabel Addon
              </h2>
            </div>
            <div class="flex items-center gap-4">
              <div class="flex flex-col">
                <h2 class="text-[22px] font-bold text-grayCust-740">
                  $20/mo
                </h2>
                <a
                  href="#"
                  target="_blank"
                  class="text-xs font-medium text-primary-900 underline"
                >Switch to Yearly</a>
              </div>
              <Switch
                type="secondary"
                size="switch-lg"
              />
            </div>
          </div>

          <div
            class="flex flex-wrap items-center justify-between gap-3 rounded-lg border border-grayCust-75 bg-white p-[20px] shadow-sm"
          >
            <div class="flex items-center gap-3.5">
              <div
                class="flex size-10 items-center justify-center rounded-md border border-primary-550 bg-grayCust-50 p-2.5"
              >
                <img
                  :src="cdn('/images/UpgradeUserModal/site-addon-icon.svg')"
                  alt=""
                >
              </div>
              <h2 class="text-lg font-semibold text-grayCust-800">
                Site Addon
              </h2>
            </div>
            <div class="flex items-center gap-4">
              <div class="flex flex-col">
                <h2 class="text-[22px] font-bold text-grayCust-740">
                  $20/mo
                </h2>
                <a
                  href="#"
                  target="_blank"
                  class="text-xs font-medium text-primary-900 underline"
                >Switch to Yearly</a>
              </div>
              <div class="flex h-9 items-center overflow-hidden rounded-md border border-grayCust-250 bg-white shadow-sm">
                <div
                  class="flex size-9 cursor-pointer items-center justify-center border-r border-grayCust-250"
                  @click="decreaseCount"
                >
                  <MinusSmIcon class="size-4 text-black" />
                </div>
                <div class="flex size-9 items-center justify-center border-r border-grayCust-250 px-3 py-[10px]">
                  <span class="font-semibold text-grayCust-800">{{ count }}</span>
                </div>
                <div
                  class="flex size-9 cursor-pointer items-center justify-center"
                  @click="increaseCount"
                >
                  <PlusSmIcon class="size-4 text-black" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="allowTrial"
        class="px-4 py-3 border border-blueCust-2050 rounded-lg bg-purpleCust-360 space-y-1.5 mb-5"
      >
        <span class="text-base font-semibold text-grayCust-1730 flex items-center gap-2">
          <img
            :src="cdn('/images/check.png')"
            alt="check"
            class="w-5 h-5"
          > 
          <label>{{ $t('you_are_activating_trial') }}</label>
        </span>
        <p class="text-sm font-medium text-grayCust-1740">
          At the end of the {{ trialDays }} days trial period <span class="font-semibold text-grayCust-1730">{{ trialEndDate }}</span> you will be billed <span class="font-semibold text-grayCust-1730">${{ total }} {{ selectedPlan?.interval == 2 ? $t('yearly') : $t('monthly') }} +</span> applicable taxes. Cancel anytime.
        </p>
      </div>
      <div
        v-if="!hidePlanSelection"
        class="pricing-container mb-6 grid grid-cols-1 gap-4 md:grid-cols-2"
      >
        <div
          v-for="item in planList"
          :key="item.id"
          class="relative  cursor-pointer rounded-lg border p-3 text-center"
          :class="selectedPlan && selectedPlan.type == item.type ? 'border-secondary-800 shadow bg-primary-275' : 'border-grayCust-75'"
          @click="selectCurrentPlan(item)"
        >
          <svg
            v-if="selectedPlan && selectedPlan.type == item.type"
            class="absolute -right-2 -top-2 text-secondary-800"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_20917_113340)">
              <rect
                x="0.666992"
                width="18"
                height="18"
                rx="9"
                fill="white"
              />
              <path
                d="M9.66699 0C4.70419 0 0.666992 4.0372 0.666992 9C0.666992 13.9628 4.70419 18 9.66699 18C14.6298 18 18.667 13.9628 18.667 9C18.667 4.0372 14.6298 0 9.66699 0ZM14.2285 7.09277L9.35347 11.9677C9.20721 12.1139 9.01523 12.1875 8.82324 12.1875C8.63126 12.1875 8.43927 12.1139 8.29301 11.9677L5.85556 9.53023C5.56223 9.23703 5.56223 8.76297 5.85556 8.46977C6.14876 8.17644 6.62268 8.17644 6.91602 8.46977L8.82324 10.377L13.1681 6.03232C13.4613 5.73898 13.9352 5.73898 14.2285 6.03232C14.5217 6.32552 14.5217 6.79944 14.2285 7.09277Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_20917_113340">
                <rect
                  x="0.666992"
                  width="18"
                  height="18"
                  rx="9"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
          <div class="flex items-center justify-center gap-6">
            <div>
              <div class="mb-2 text-base font-semibold text-grayCust-730">
                {{ item.name }}
              </div>
              <div class="whitespace-pre text-xl font-bold text-grayCust-430">
                ${{ item.price }}<span class="text-base font-normal text-grayCust-620">/{{ item.interval == 2 ?
                  $t('year') : $t('month') }}</span>
              </div>
            </div>
            <div
              v-if="item.name == 'Professional' && $page.props.APP_ENV !== 'production'"
              class="bg-blueCust-2000 text-blueCust-1950 text-sm font-medium rounded px-1 py-1"
            >
              Best Value 2 Months free
            </div>
          </div>
        </div>
      </div>

      <!-- your cards -->
      <div
        v-show="!showAddNewCard && !applyOffer"
        class="relative mb-6 rounded-lg border border-grayCust-75 p-4"
      >
        <label class="mb-1.5 block text-sm font-medium text-grayCust-620">{{ $t('your_cards') }}</label>
        <div
          v-if="loadingCards"
          class="align-center flex h-36 justify-center py-4"
        >
          <svg
            role="status"
            class="my-auto size-8 animate-spin"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#E5E7EB"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <div class="custom-scroll max-h-[160px] overflow-y-auto pr-2">
          <div
            v-for="item in list"
            :key="item.id"
            class="relative mb-3 flex cursor-pointer items-center rounded-lg border border-grayCust-390"
            @click="setDefaultCard(item)"
          >
            <div class="flex w-full items-center justify-between">
              <div class="flex items-center rounded-l-lg bg-grayCust-770 p-4">
                <!-- <img :src="cdn('images/UpgradeUserModal/visa-img.png')" class="w-20"> -->
                <component
                  :is="item.card.brand"
                  v-if="item.card.brand != 'American Express'"
                />
                <component
                  :is="'Americanexpress'"
                  v-if="item.card.brand == 'American Express' || item.card.brand == 'amex'"
                />
              </div>
              <div
                class="form-control block w-full rounded-r-lg border-0 px-4 py-3 text-sm uppercase text-grayCust-500 focus:border-0 focus:ring-0"
              >
                {{ $t('card_ending_with_xxxx') }}{{ item.card.last4 }}
              </div>
              <div
                v-if="item.processing || item.id == default_source"
                class="ml-auto mr-4"
              >
                <svg
                  v-if="!item.processing && item.id == default_source"
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_20917_113556)">
                    <rect
                      y="0.736328"
                      width="18"
                      height="18"
                      rx="9"
                      fill="white"
                    />
                    <path
                      d="M9 0.736328C4.0372 0.736328 0 4.77353 0 9.73633C0 14.6991 4.0372 18.7363 9 18.7363C13.9628 18.7363 18 14.6991 18 9.73633C18 4.77353 13.9628 0.736328 9 0.736328ZM13.5615 7.8291L8.68648 12.704C8.54022 12.8503 8.34824 12.9239 8.15625 12.9239C7.96426 12.9239 7.77228 12.8503 7.62602 12.704L5.18857 10.2666C4.89523 9.97336 4.89523 9.4993 5.18857 9.2061C5.48177 8.91277 5.95569 8.91277 6.24902 9.2061L8.15625 11.1133L12.5011 6.76865C12.7943 6.47531 13.2682 6.47531 13.5615 6.76865C13.8547 7.06184 13.8547 7.53577 13.5615 7.8291Z"
                      fill="#15B881"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_20917_113556">
                      <rect
                        y="0.736328"
                        width="18"
                        height="18"
                        rx="9"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <svg
                  v-if="item.processing"
                  role="status"
                  class="size-4 animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div v-show="errors.payment_method.length > 0">
          <p class="ml-1 mt-1 text-xs text-red-600">
            {{ errors.payment_method }}
          </p>
        </div>
        <div
          class="cursor-pointer text-xs font-semibold uppercase tracking-wide text-primary-900"
          @click="showAddNewCard = true"
        >
          <span class="text-lg"> +
          </span>{{ $t('add_new_card') }}
        </div>
      </div>

      <!-- add new card -->
      <div
        v-show="showAddNewCard && !applyOffer"
        class="mb-6 rounded-lg border border-grayCust-75 p-4"
        style="box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04);"
      >
        <div
          v-if="list.length > 0"
          class="mb-3 inline-flex cursor-pointer items-center text-sm font-medium text-secondary-800"
          @click="showAddNewCard = false"
        >
          <svg
            class="mr-1 text-secondary-800"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6673 8.23698H3.33398M3.33398 8.23698L8.00065 12.9036M3.33398 8.23698L8.00065 3.57031"
              stroke="currentColor"
              stroke-width="1.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ $t('back') }}
        </div>
        <div class="pricing-tooltip relative mb-5">
          <label
            for="card-number"
            class=" mb-1.5 inline-flex items-center text-sm font-medium text-grayCust-620"
          >Card
            number <div
              v-tooltip="$t('make_sure_your_credit_cards_have_enough_funds_for_this_transaction')"
              class="cursor-pointer"
            >
              <img
                :src="cdn('images/UpgradeUserModal/help-icon.svg')"
                class="ml-2"
              >
            </div>
          </label>
          <fieldset>
            <div>
              <div
                id="card-element"
                class="custom-shadow rounded-lg border border-grayCust-390 text-sm shadow-sm focus:border-secondary-800 focus:ring-1 focus:ring-secondary-800"
              />
              <p
                v-show="errors.payment_method.length > 0"
                class="ml-1 mt-1 text-xs text-red-600"
              >
                {{ errors.payment_method }}
              </p>
            </div>
          </fieldset>
        </div>
        <div class="mb-5">
          <label
            for="card_holder"
            class=" mb-1.5 inline-flex items-center text-sm font-medium text-grayCust-620"
          >
            {{ $t('card_holder_name') }}
          </label>
          <input
            id="card_holder"
            v-model.trim="cardHolderName"
            type="text"
            name="card_holder"
            class="block w-full rounded-md border-grayCust-390 py-2.5 shadow-sm focus:border-secondary-800 focus:ring-1 focus:ring-secondary-800 sm:text-sm"
            :placeholder="$t('enter_card_holder_name')"
          >
        </div>
        <div class="flex flex-wrap items-center justify-between lg:flex-nowrap">
          <div class="mb-2 inline-flex items-center text-xs font-normal text-grayCust-910 lg:mb-0">
            {{ $t('your_card_is_securely_saved_with') }}
            <img
              :src="cdn('images/UpgradeUserModal/stripe-icon.png')"
              class="w-10"
            >
          </div>
          <div
            v-if="errors && errors.payment_method"
            class="text-xs font-normal"
            style="color: #F00;"
          >
            {{ errors.payment_method }}
          </div>
        </div>
        <p
          v-if="cardVerification == 'true' || allowTrial"
          class="mt-1 text-xs text-red-400"
        >
          {{ $t('card_verify_notice') }}
        </p>
      </div>
      <div
        v-if="!showCodeInput && !applyOffer && !couponApplied"
        class="my-4"
      >
        <div
          class="flex flex-wrap items-center justify-between gap-3 rounded-lg border border-secondary-750 bg-primary-275 px-3 py-2.5"
        >
          <div
            class="flex cursor-pointer items-center gap-2"
            @click="showCodeInput = true"
          >
            <img
              :src="cdn('images/UpgradeUserModal/coopan-icon.svg')"
              class="size-4"
              alt=""
            >
            <div class="flex items-center">
              <h6 class="text-sm font-medium text-secondary-900 underline underline-offset-4">
                {{
                  $t('apply_coupon') }}
              </h6>
            </div>
          </div>
          <div
            v-if="Array.isArray(offers) && offers.length"
            class="apply-coupon group flex cursor-pointer items-center gap-1"
            @click="applyOffer = true"
          >
            <h6 class="text-sm font-medium text-grayCust-1100 group-hover:text-primary-900">
              {{
                offers.length }} {{ $t('offers_available') }}
            </h6>
            <svg
              class="size-4 group-hover:text-primary-900"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="vuesax/outline/arrow-right">
                <g id="arrow-right">
                  <path
                    id="Vector"
                    d="M5.94023 14.5163C5.81356 14.5163 5.68689 14.4697 5.58689 14.3697C5.39356 14.1763 5.39356 13.8563 5.58689 13.663L9.93356 9.31634C10.2536 8.99634 10.2536 8.47634 9.93356 8.15634L5.58689 3.80967C5.39356 3.61634 5.39356 3.29634 5.58689 3.10301C5.78023 2.90967 6.10023 2.90967 6.29356 3.10301L10.6402 7.44967C10.9802 7.78967 11.1736 8.24967 11.1736 8.73634C11.1736 9.22301 10.9869 9.68301 10.6402 10.023L6.29356 14.3697C6.19356 14.463 6.06689 14.5163 5.94023 14.5163Z"
                    fill="currentColor"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div
        v-if="(showCodeInput && !applyOffer) || couponApplied"
        class="mb-6"
      >
        <div
          v-if="!couponApplied"
          class="form-control relative mb-3 flex w-full items-center rounded-lg border border-grayCust-390 bg-white focus:border-primary-900 focus:ring-2 focus:ring-primary-900"
        >
          <div class="ml-3">
            <img
              :src="cdn('images/UpgradeUserModal/coopan-icon.svg')"
              class="size-9"
            >
          </div>
          <input
            :value="appStore.coupon.coupon_code"
            type="text"
            class="w-full rounded-md border-0 text-base focus:ring-0 focus:ring-transparent"
            placeholder="Enter coupon code "
            @input="updateCouponCode"
          >
          <div class="flex items-center">
            <button
              class="mr-1"
              @click="showCodeInput = false"
            >
              <svg
                class=""
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_20715_136646)">
                  <path
                    d="M8 15.2363C6.14348 15.2363 4.36301 14.4988 3.05025 13.1861C1.7375 11.8733 1 10.0928 1 8.23633C1 6.37981 1.7375 4.59934 3.05025 3.28658C4.36301 1.97383 6.14348 1.23633 8 1.23633C9.85652 1.23633 11.637 1.97383 12.9497 3.28658C14.2625 4.59934 15 6.37981 15 8.23633C15 10.0928 14.2625 11.8733 12.9497 13.1861C11.637 14.4988 9.85652 15.2363 8 15.2363ZM8 16.2363C10.1217 16.2363 12.1566 15.3935 13.6569 13.8932C15.1571 12.3929 16 10.3581 16 8.23633C16 6.1146 15.1571 4.07976 13.6569 2.57947C12.1566 1.07918 10.1217 0.236328 8 0.236328C5.87827 0.236328 3.84344 1.07918 2.34315 2.57947C0.842855 4.07976 0 6.1146 0 8.23633C0 10.3581 0.842855 12.3929 2.34315 13.8932C3.84344 15.3935 5.87827 16.2363 8 16.2363Z"
                    fill="#FE5F55"
                  />
                  <path
                    d="M4.64689 4.88322C4.69334 4.83666 4.74851 4.79972 4.80926 4.77451C4.87001 4.7493 4.93513 4.73633 5.00089 4.73633C5.06666 4.73633 5.13178 4.7493 5.19253 4.77451C5.25327 4.79972 5.30845 4.83666 5.35489 4.88322L8.00089 7.53022L10.6469 4.88322C10.6934 4.83673 10.7486 4.79986 10.8093 4.7747C10.87 4.74954 10.9351 4.73659 11.0009 4.73659C11.0666 4.73659 11.1317 4.74954 11.1925 4.7747C11.2532 4.79986 11.3084 4.83673 11.3549 4.88322C11.4014 4.92971 11.4383 4.9849 11.4634 5.04564C11.4886 5.10638 11.5015 5.17148 11.5015 5.23722C11.5015 5.30297 11.4886 5.36807 11.4634 5.42881C11.4383 5.48954 11.4014 5.54473 11.3549 5.59122L8.70789 8.23722L11.3549 10.8832C11.4014 10.9297 11.4383 10.9849 11.4634 11.0456C11.4886 11.1064 11.5015 11.1715 11.5015 11.2372C11.5015 11.303 11.4886 11.3681 11.4634 11.4288C11.4383 11.4895 11.4014 11.5447 11.3549 11.5912C11.3084 11.6377 11.2532 11.6746 11.1925 11.6997C11.1317 11.7249 11.0666 11.7379 11.0009 11.7379C10.9351 11.7379 10.87 11.7249 10.8093 11.6997C10.7486 11.6746 10.6934 11.6377 10.6469 11.5912L8.00089 8.94422L5.35489 11.5912C5.30841 11.6377 5.25322 11.6746 5.19248 11.6997C5.13174 11.7249 5.06664 11.7379 5.00089 11.7379C4.93515 11.7379 4.87005 11.7249 4.80931 11.6997C4.74857 11.6746 4.69338 11.6377 4.64689 11.5912C4.60041 11.5447 4.56353 11.4895 4.53837 11.4288C4.51321 11.3681 4.50026 11.303 4.50026 11.2372C4.50026 11.1715 4.51321 11.1064 4.53837 11.0456C4.56353 10.9849 4.60041 10.9297 4.64689 10.8832L7.29389 8.23722L4.64689 5.59122C4.60033 5.54478 4.56339 5.4896 4.53818 5.42886C4.51297 5.36811 4.5 5.30299 4.5 5.23722C4.5 5.17145 4.51297 5.10633 4.53818 5.04559C4.56339 4.98484 4.60033 4.92967 4.64689 4.88322Z"
                    fill="#FE5F55"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_20715_136646">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.236328)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <button
              :disabled="!appStore.coupon.coupon_code"
              class="cursor-pointer whitespace-nowrap rounded-r-lg bg-grayCust-110 px-5 py-2.5 text-sm font-medium text-primary-900"
              @click="applyCoupon"
            >
              {{ $t('apply_coupon') }}
            </button>
          </div>
        </div>
        <div
          v-else
          class="form-control relative flex w-full items-center justify-between bg-white"
        >
          <div class="flex items-center">
            <div>
              <img
                :src="cdn('images/UpgradeUserModal/coopan-icon.svg')"
                class="size-9"
              >
            </div>
            <input
              :readonly="couponApplied"
              :disabled="couponApplied"
              :value="appStore.coupon.coupon_code"
              type="text"
              class="ml-2.5 w-full max-w-[108px] rounded-md border border-dashed border-secondary-800 px-1 py-0 text-base text-secondary-800 focus:ring-0 focus:ring-transparent "
              @input="updateCouponCode"
            >
            <div class="ml-3 text-sm font-normal text-grayCust-910">
              {{
                $t('coupon_code_applied') }}
            </div>
          </div>
          <div class="flex items-center">
            <div class="text-sm font-medium text-primary-900">
              -${{ couponCodeValue }}
            </div>
            <svg
              class="ml-2 cursor-pointer"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="removeCoupon"
            >
              <g clip-path="url(#clip0_20715_136646)">
                <path
                  d="M8 15.2363C6.14348 15.2363 4.36301 14.4988 3.05025 13.1861C1.7375 11.8733 1 10.0928 1 8.23633C1 6.37981 1.7375 4.59934 3.05025 3.28658C4.36301 1.97383 6.14348 1.23633 8 1.23633C9.85652 1.23633 11.637 1.97383 12.9497 3.28658C14.2625 4.59934 15 6.37981 15 8.23633C15 10.0928 14.2625 11.8733 12.9497 13.1861C11.637 14.4988 9.85652 15.2363 8 15.2363ZM8 16.2363C10.1217 16.2363 12.1566 15.3935 13.6569 13.8932C15.1571 12.3929 16 10.3581 16 8.23633C16 6.1146 15.1571 4.07976 13.6569 2.57947C12.1566 1.07918 10.1217 0.236328 8 0.236328C5.87827 0.236328 3.84344 1.07918 2.34315 2.57947C0.842855 4.07976 0 6.1146 0 8.23633C0 10.3581 0.842855 12.3929 2.34315 13.8932C3.84344 15.3935 5.87827 16.2363 8 16.2363Z"
                  fill="#FE5F55"
                />
                <path
                  d="M4.64689 4.88322C4.69334 4.83666 4.74851 4.79972 4.80926 4.77451C4.87001 4.7493 4.93513 4.73633 5.00089 4.73633C5.06666 4.73633 5.13178 4.7493 5.19253 4.77451C5.25327 4.79972 5.30845 4.83666 5.35489 4.88322L8.00089 7.53022L10.6469 4.88322C10.6934 4.83673 10.7486 4.79986 10.8093 4.7747C10.87 4.74954 10.9351 4.73659 11.0009 4.73659C11.0666 4.73659 11.1317 4.74954 11.1925 4.7747C11.2532 4.79986 11.3084 4.83673 11.3549 4.88322C11.4014 4.92971 11.4383 4.9849 11.4634 5.04564C11.4886 5.10638 11.5015 5.17148 11.5015 5.23722C11.5015 5.30297 11.4886 5.36807 11.4634 5.42881C11.4383 5.48954 11.4014 5.54473 11.3549 5.59122L8.70789 8.23722L11.3549 10.8832C11.4014 10.9297 11.4383 10.9849 11.4634 11.0456C11.4886 11.1064 11.5015 11.1715 11.5015 11.2372C11.5015 11.303 11.4886 11.3681 11.4634 11.4288C11.4383 11.4895 11.4014 11.5447 11.3549 11.5912C11.3084 11.6377 11.2532 11.6746 11.1925 11.6997C11.1317 11.7249 11.0666 11.7379 11.0009 11.7379C10.9351 11.7379 10.87 11.7249 10.8093 11.6997C10.7486 11.6746 10.6934 11.6377 10.6469 11.5912L8.00089 8.94422L5.35489 11.5912C5.30841 11.6377 5.25322 11.6746 5.19248 11.6997C5.13174 11.7249 5.06664 11.7379 5.00089 11.7379C4.93515 11.7379 4.87005 11.7249 4.80931 11.6997C4.74857 11.6746 4.69338 11.6377 4.64689 11.5912C4.60041 11.5447 4.56353 11.4895 4.53837 11.4288C4.51321 11.3681 4.50026 11.303 4.50026 11.2372C4.50026 11.1715 4.51321 11.1064 4.53837 11.0456C4.56353 10.9849 4.60041 10.9297 4.64689 10.8832L7.29389 8.23722L4.64689 5.59122C4.60033 5.54478 4.56339 5.4896 4.53818 5.42886C4.51297 5.36811 4.5 5.30299 4.5 5.23722C4.5 5.17145 4.51297 5.10633 4.53818 5.04559C4.56339 4.98484 4.60033 4.92967 4.64689 4.88322Z"
                  fill="#FE5F55"
                />
              </g>
              <defs>
                <clipPath id="clip0_20715_136646">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.236328)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div
          v-if="errors && errors.coupon_code"
          class="mt-2 text-xs font-normal"
          style="color: #F00;"
        >
          {{ errors.coupon_code }}
        </div>
      </div>
      <ApplyOffer
        v-if="applyOffer && Array.isArray(offers) && offers.length"
        @back-click="applyOffer = false"
        @offer-applied="offerApplied"
      />
      <div
        v-if="selectedPlan && couponApplied && !appliedCouponDetails.plan_ids.includes(selectedPlan.stripe_plan_id)"
        class="mt-2 text-sm font-normal"
        style="color: #F00;"
      >
        {{ $t('coupon_not_applicable_notice') }}
      </div>
      <div
        v-if="!allowTrial && selectedPlan && !(couponApplied && !appliedCouponDetails.plan_ids.includes(selectedPlan.stripe_plan_id))"
        class="mb-6 flex flex-wrap items-center justify-between lg:flex-nowrap"
      >
        <div
          class="text-normal mb-3 text-base text-black lg:mb-0"
        >
          {{ $t('you_will_be_charged') }}
          <span class="font-bold">${{ total }} {{ $t('per') }} {{ selectedPlan.interval ==
            2 ? $t('year') : $t('month') }}</span>
        </div>
        <div
          class="hover-text relative cursor-pointer text-sm font-normal text-grayCust-620 underline underline-offset-4"
        >
          {{ $t('view_breakdown') }}
          <div class="hover-box absolute z-50 w-[280px] rounded-lg bg-primary-1450 p-3 text-white">
            <PlanTooltip
              :total="total"
              :sub-total="subTotal"
              :coupon-code="couponName"
              :coupon-code-value="couponCodeValue"
            />
          </div>
        </div>
      </div>

      <button
        v-if="selectedPlan && !(couponApplied && !appliedCouponDetails.plan_ids.includes(selectedPlan.stripe_plan_id))"
        :disabled="processing || (showAddNewCard && !cardHolderName)"
        class="inline-flex w-full justify-center rounded-lg bg-secondary-800 py-3 text-base font-semibold text-white focus:ring-2  focus:ring-secondary-800 focus:ring-offset-2 disabled:opacity-50 "
        @click="onSubmit"
      >
        <div
          v-if="!processing"
          class="inline-flex"
        >
          <svg
            class="mr-2"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.6673 18.5703H6.33398C5.99232 18.5703 5.70898 18.287 5.70898 17.9453C5.70898 17.6036 5.99232 17.3203 6.33398 17.3203H14.6673C15.009 17.3203 15.2923 17.6036 15.2923 17.9453C15.2923 18.287 15.009 18.5703 14.6673 18.5703Z"
              fill="white"
            />
            <path
              d="M17.4575 4.83555L14.1242 7.21888C13.6825 7.53555 13.0492 7.34388 12.8575 6.83555L11.2825 2.63555C11.0159 1.91055 9.99087 1.91055 9.72421 2.63555L8.14087 6.82721C7.94921 7.34388 7.32421 7.53555 6.88254 7.21055L3.54921 4.82721C2.88254 4.36055 1.99921 5.01888 2.27421 5.79388L5.74087 15.5022C5.85754 15.8355 6.17421 16.0522 6.52421 16.0522H14.4659C14.8159 16.0522 15.1325 15.8272 15.2492 15.5022L18.7159 5.79388C18.9992 5.01888 18.1159 4.36055 17.4575 4.83555ZM12.5825 12.5272H8.41587C8.07421 12.5272 7.79087 12.2439 7.79087 11.9022C7.79087 11.5605 8.07421 11.2772 8.41587 11.2772H12.5825C12.9242 11.2772 13.2075 11.5605 13.2075 11.9022C13.2075 12.2439 12.9242 12.5272 12.5825 12.5272Z"
              fill="white"
            />
          </svg>
          <span v-if="allowTrial">{{ $t('start_your_days_trial', { days: trialDays }) }}</span>
          <span v-else>{{ $t('upgrade_to') }} {{ selectedPlan.name }}</span>
        </div>
        <div v-else>
          <svg
            role="status"
            class="size-5 animate-spin"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#E5E7EB"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import Header from '@/Common/UpgradeUserModal/Header.vue';
import PlanTooltip from '@/Common/UpgradeUserModal/PlanTooltip.vue';

import IconButton from '@/components/New/Blocks/Buttons/IconButton.vue';
import Switch from '@/components/New/Blocks/Switch/Switch.vue';
import Tab from '@/components/New/Blocks/Tabs/Tab.vue';
import Americanexpress from '@/components/UpdatedDesignVersion/ImageComponents/Profile/AmericanExpress.vue';
import Mastercard from '@/components/UpdatedDesignVersion/ImageComponents/Profile/MasterCard.vue';
import Visa from '@/components/UpdatedDesignVersion/ImageComponents/Profile/Visa.vue';
import ApplyOffer from "@/components/UpgradeUserModal/ApplyOffer.vue";
import { features } from '@/features.js';
import { getClientReferenceId } from '@/helpers';
import { useAppStore } from '@/store/index';
import StripePayment from "@/StripePayment";
import { MinusSmIcon, PlusSmIcon } from "@heroicons/vue/outline";
import { mapState, mapStores } from 'pinia';
export default {
  name: "PlanInformation",
  components: {
    Header,
    PlanTooltip,
    Visa,
    Mastercard,
    Americanexpress,
    ApplyOffer,
    Tab,
    Switch,
    IconButton,
    PlusSmIcon,
    MinusSmIcon,
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    hidePlanSelection: {
      type: Boolean,
      default: false
    },
    hideSubtitle: {
      type: Boolean,
      default: false
    },
    featureList: {
      type: Array,
      required: true
    },
    customPlans: {
      type: Array,
      required: false,
      default: () => []
    },
    dialogTitle: {
      type: String,
      required: false,
      default: ''
    },
    dialogSubtitle: {
      type: String,
      required: false,
      default: ''
    },

  },
  data() {
    return {
      app: {},
      card: {
        clientSecret: null,
        cardLastFour: null
      },
      showCodeInput: false,
      stripe: null,
      loading: false,
      form: {
        payment_method: ''
      },
      errors: {
        payment_method: '',
      },
      loadStripeTimeout: null,
      planList: [],
      selectedPlan: null,
      cardHolderName: '',
      default_source: null,
      list: [],
      showAddNewCard: false,
      currentItemID: null,
      processing: false,
      loadingCards: true,
      applyOffer: false,
      cardVerification: import.meta.env.VITE_CARD_VERIFICATION_ENABLED,
      count: 18,
      selectedPlanTab: 2,
      tabList: [
        {
          id: 1,
          title: 'Upgrade Plan',
        },
        {
          id: 2,
          title: 'Buy Addons'
        },
      ],
      trialDays: 0,
    }
  },
  computed: {
    couponCode() {
      return this.appStore.coupon.coupon_code;
    },
    couponName() {
      return this.appStore.coupon.name;
    },
    ...mapStores(useAppStore),
    ...mapState(useAppStore, { "couponApplied": "shouldCouponApply", "appliedCouponDetails": "appliedCoupon" }),
    ...mapState(useAppStore, ["ui", "getSelectedPlan", "plans", "user", "upgradeFreeToPaid", "offers","teamOwnerActivePlan"]),
    total() {
      if (this.selectedPlan) {
        const price = this.selectedPlan?.yearlyPrice ? this.selectedPlan.yearlyPrice : this.selectedPlan.price
        return Math.max((price - Number(this.couponCodeValue)), 0).toFixed(2)
      }
      return '0.00'
    },
    subTotal() {
      if (this.selectedPlan) {
        const price = this.selectedPlan?.yearlyPrice ? this.selectedPlan.yearlyPrice : this.selectedPlan.price
        return price?.toFixed(2) || '0.00'
      }
      return '0.00'
    },
    couponCodeValue() {
      if (this.couponApplied && this.appStore?.coupon) {
        if (this.appStore.coupon.discount_type == "percentage") {
          if (this.selectedPlan) {
            const price = this.selectedPlan?.yearlyPrice ? this.selectedPlan.yearlyPrice : this.selectedPlan.price
            if (price && this.appStore.coupon.discount_value) {
              return ((price * this.appStore.coupon.discount_value) / 100).toFixed(2)
            }
          }
        } else if (this.appStore.coupon.discount_value) {
          return this.appStore.coupon.discount_value.toFixed(2)
        }
      }
      return '0.00'
    },
    featureName() {
      if (this.upgradeFreeToPaid && this.upgradeFreeToPaid.feature) {
        const feature = features.find(i => i.key == this.upgradeFreeToPaid.feature)
        return feature ? this.$t(feature.name) : ''
      }
      return ''
    },
    trialEndDate() {
      if (this.trialDays > 0) {
        const today = new Date();
        const trialEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + parseInt(this.trialDays));
        return trialEndDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
      }
      return '';
    },
    allowTrial() {
      return this.trialDays > 0 && !this.couponApplied;
    }
  },
  watch: {
    // whenever question changes, this function will run
    show: {
      handler(value, oldValue) {
        if (value) {
          this.loadStripe();
          this.getIntentKey();
          if (this.customPlans.length === 0) {
            this.setupPlanList();
          } else {
            this.planList = this.customPlans;
          }

          this.form.payment_method = '';
        }
      },
      immediate: true
    },
    planList: {
      handler(newValue, oldValue) {
        if (newValue && newValue.length > 0) {
          this.selectedPlan = newValue[0]
          this.$emit('onPlanSelection', this.selectedPlan)
          if (this.couponApplied) {
            this.showCodeInput = true
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.app = window.APP;
    this.trialDays = this.$page.props.trial_days && this.teamOwnerActivePlan.type == 'free'?this.$page.props.trial_days:0;
  },
  unmounted() {
    clearTimeout(this.loadStripeTimeout)
  },
  methods: {
    increaseCount() {
      this.count++;
    },
    decreaseCount() {
      if (this.count > 0) {
        this.count--;
      }
    },
    selectCurrentPlan(item) {
      this.selectedPlan = item
      this.$emit('onPlanSelection', item)
    },
    updateCouponCode(e) {
      this.appStore.changeCouponCode(e.target.value)
    },
    removeCoupon() {
      this.showCodeInput = false
      this.appStore.removeCoupon();
    },
    applyCoupon() {
      const postData = {
        coupon_code: this.couponCode,
      }
      axios
        .post("/api/v1/validate-coupon", postData)
        .then(response => {
          const coupon = response.data.coupon
          this.appStore.applyCoupon(coupon);
        }).catch((error) => {
          if (error.response.status != 422) {
            const message = error?.response?.data.message || error.message
            this.errorMessage = {
              title: this.$t('coupon_error'),
              message: message
            }
          }
        })
    },
    setupPlanList() {
      this.planList = []
      const names = this.appStore.upgradeFreeToPaid.planNames
      if (names) {
        if (names['yearly']) {
          this.planList.push(this.plans.find(i => i.name == names['yearly']))
        }
        if (names['monthly']) {
          this.planList.push(this.plans.find(i => i.name == names['monthly']))
        }
      }
    },
    loadStripe() {
      const url = `https://js.stripe.com/v3?callback=initStripe`;
      const script = document.createElement('script');
      if (document.querySelectorAll(`script[src="${url}"]`).length == 0) {
        script.setAttribute("src", url);
        document.head.appendChild(script);
        script.onload = () => this.stripeInit();
      } else {
        this.loadStripeTimeout = setTimeout(() => {
          this.stripeInit();
        }, 30);
      }
    },
    getIntentKey() {
      axios
        .get("/get-intent-key")
        .then((response) => {
          this.card.clientSecret = response.data.data.data.clientSecret;
          this.card.cardLastFour = response.data.data.data.cardLastFour;
          this.loadingCards = true
          this.listCard(true);
        })
        .catch((error) => {
        })
        .finally(() => {
        });
    },
    stripeInit() {
      try {
        this.stripe = new StripePayment;
        this.stripe.mountTo("#card-element", {
          fields: {
            billingDetails: {
              name: 'auto'
            }
          }
        }).onChange((ex) => {
          this.errors.payment_method = ex.error ? ex.error.message : '';
        });
      } catch (error) {
        
      }
    },
    onSubmit() {
      this.processing = true
      if (this.showAddNewCard) {
        this.confirmCardSetup();
      } else {
        this.updateSubscription()
      }
    },
    confirmCardSetup() {
      this.stripe.confirmCardSetup(this.user.email, this.cardHolderName, this.stripe.getCardElement(), this.card.clientSecret, (result) => {
        if (result.setupIntent && result.setupIntent.payment_method) {
          this.form.card_deduction = this.allowTrial ? true :this.cardVerification;
          this.form.payment_method = result.setupIntent.payment_method;
          this.update();
          return;
        }
        if (result.error) {
          this.loading = false;
          this.processing = false
          this.errors.payment_method = result.error ? result.error.message : '';
          return;
        }
      });
    },
    update() {
      axios.patch('/card', this.form).then(async (response) => {
        this.card.cardLastFour = response.data.card_last_four;
        this.appStore.setCardIsActivated(true)
        await this.listCard();
        this.updateSubscription()
      }).catch((error) => {
        
        this.processing = false
        if (error.response.status === 422 && error.response.data && error.response.data.errors) {
          const messages = error.response.data.errors;
          if (messages.payment_method && messages.payment_method.length > 0) {
            this.errors.payment_method = messages.payment_method[0];
            return;
          }
        }
      });
    },
    async listCard(isInitialLoading) {
      axios
        .get("/list-source")
        .then((response) => {
          this.list = response.data.data.card.list;
          this.default_source = response.data.data.card.default_source;
          if (this.list.length == 0) {
            this.showAddNewCard = true
          }
        })
        .catch((error) => {
        })
        .finally(() => {
          if (isInitialLoading) {
            this.loadingCards = false
          }
        });
    },
    setDefaultCard(card) {
      if (this.default_source == card.id) {
        return false
      }
      card.processing = true
      axios
        .post("/update-default-source", { id: card.id })
        .then((response) => {
          this.listCard();
        })
        .catch((error) => {
        })
        .finally(() => {
          card.processing = false;
        });
    },
    checkCoupon(plan) {
      return this.couponApplied && this.appliedCouponDetails.plan_ids && (this.appliedCouponDetails.plan_ids.length == 0
        || this.appliedCouponDetails.plan_ids.findIndex((planID) => planID == plan.stripe_plan_id) != -1);
    },
    offerApplied(coupon_code) {
      const that = this
      const myPromise = new Promise((resolve, reject) => {
        that.appStore.changeCouponCode(coupon_code)
        resolve(true);
      });
      myPromise.then((response) => {
        that.applyCoupon()
      }).then((response) => {
        that.applyOffer = false
      }).then((response) => {
        that.showCodeInput = true
      }).catch((error) => {
        
      });
    },
    updateSubscription() {
      const plan = this.selectedPlan;
      this.errorMessage = null;
      if (this.couponApplied && !this.checkCoupon(plan)) {
        this.processing = false
        this.errors.coupon_code = this.$t('invalid_coupon_plan')
        return false;
      }
      const referral = getClientReferenceId();
      axios.patch('/subscription/plans', {
        plan: plan.type,
        interval: plan.interval,
        features: null,
        plan_name: null,
        is_custom_plan: false,
        coupon_code: this.checkCoupon(plan) ? this.couponCode : null,
        referral,
        trial_days: this.allowTrial ? this.trialDays : null
      }).then((response) => {
        const price = this.total
        this.removeCoupon()
        if (response.data.link) {
          window.location.href = response.data.link;
        } else if (this.appStore.upgradeFreeToPaid?.feature) {
          const feature = features.find(i => i.key == this.appStore.upgradeFreeToPaid.feature)
          axios.get("/api/v2/upcoming-invoice").then((res) => {
            const upcomingInvoiceTable = res.data.data;
            this.appStore.setPaymentSuccessModal({
              featureList: [...this.$props.featureList],
              planName: this.selectedPlan.name,
              price: price,
              interval: this.selectedPlan.interval == 1 ? 'monthly' : 'yearly',
              feature: feature,
              featureValue: feature.type == 'numeric' ? this.selectedPlan.description[this.appStore.upgradeFreeToPaid.feature] : (this.selectedPlan.description[this.appStore.upgradeFreeToPaid.feature] ? this.$t('enabled') : this.$t('disabled')),
              expiry: upcomingInvoiceTable.date
            })
            this.$emit('close')
          })
        } else {
          window.location.href = '/subscriptions';
        }
      }).catch((error) => {
        
        this.processing = false
        if (error.response.status === 422 && error.response.data && error.response.data.errors) {
          const messages = error.response.data.errors;
          if (messages.plan && messages.plan.length > 0) {
            this.error = messages.plan[0];
            this.errors.payment_method = error.response.data.message
          } else if (messages.link) {
            this.errors.payment_method = messages.message
          }
        }
        this.getIntentKey()
      })
    },
  },
}
</script>
<style scoped>

</style>