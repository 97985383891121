<template>
  <CPopover 
    panel-class="w-[200px]"
  >
    <template #popoverButton>
      <div
        class="flex items-center gap-2 w-full cursor-pointer rounded-md bg-white/10 py-[3px] px-2.5 text-left text-gray-900 border border-white/20"
        :class="[justify, extraClass]"
      >
        <div class="flex items-center gap-2">
          <span class="flex items-center gap-2">
            <img
              v-lazy="selectedOption?.store_logo_url"
              alt=""
              class="size-6 shrink-0 rounded-full"
            >
            <component
              :is="avatarIcon"
              class="size-5 text-white"
            />
            <span class="block truncate text-xs text-grayCust-50 font-semibold max-w-[110px]">{{ selectedOption?.name }}</span>
          </span>
          <OutlineSelectorIcon
            class=" size-5 self-center justify-self-end text-grayCust-550 sm:size-4"
            aria-hidden="true"
          />
        </div>
      </div>
    </template>
    <template #popoverOptions>
      <div
        v-if="myTeam.heading"
        class="mb-1"
      >
        <div class="flex items-center justify-between gap-2 px-4 pt-3 pb-2">
          <span class="text-grayCust-700 font-medium text-sm">{{ myTeam.heading }}</span>
          <!-- <CButton
            btn-title="Create New"
            btn-type="gray-text-btn"
            icon-name="OutlinePlusIcon"
            size="btn-xs"
          /> -->
          <inertia-link
            v-if="page.props.jetstream.canCreateTeams && userActivePlan.description?.teams"
            as="div"
            :href="route('teams.create')"
            class="cursor-pointer flex items-center gap-1 text-sm"
            @click.stop
          >
            <OutlinePlusIcon class="size-3 text-grayCust-980" />
            <span class="text-grayCust-980 text-xs">Create New</span>
          </inertia-link>
          <CButton
            v-else
            :btn-title="$t('create_team')"
            btn-type="secondary-text-btn"
            icon-name="OutlineLockClosedIcon"
            size="btn-xs"
            @click="showUpgradeWarning('teams')"
          />
        </div>
        <div class="max-h-[200px] overflow-y-auto custom-scrollbar-v2">
          <div
            v-for="team in myTeams"
            :key="team.id"
            @click="navigateToTeam(team.href && team.href !== '#' ? route(team.href) : '')"
          >
            <li
              :class="[
                selectedOption.id === team.id ? 'bg-primary-275 text-grayCust-700 font-semibold hover:bg-primary-275' : '',
                'py-2 px-4 cursor-pointer relative z-0 hover:bg-grayCust-100 flex items-center text-sm font-normal gap-2 justify-between'
              ]"
              @click.prevent="handleSelection(team)"
            >
              <div class="flex items-center gap-2">
                <img
                  v-if="team.store_logo_url"
                  v-lazy="team.store_logo_url"
                  alt=""
                  class="size-6 shrink-0 rounded-full"
                >
                <span class="block truncate text-grayCust-640 w-[110px]">{{ team.name }}</span>
              </div>
              <div class="flex items-center gap-2">
                <OutlineCheckIcon
                  v-if="selectedOption.id === team.id"
                  class="size-4 text-secondary-800"
                />
                <inertia-link
                  as="div"
                  :href="route('teams.show', team.id)"
                  @click.stop
                >
                  <OutlineCogIcon class="size-4 text-grayCust-550" />
                </inertia-link>
              </div>
            </li>
          </div>
        </div>
      </div>
      <div
        v-if="myTeam.sharedWithMe && sharedWithMeTeams.length > 0"
        class="border-t border-grayCust-100 my-1"
      >
        <div class="flex items-center justify-between gap-2 px-4 pt-3 pb-1">
          <span class="text-grayCust-700 font-medium text-sm">{{ myTeam.sharedWithMe.heading }}</span>
        </div>
        <div class="max-h-[200px] overflow-y-auto custom-scrollbar-v2">
          <div
            v-for="team in sharedWithMeTeams"
            :key="team.id"
            @click="navigateToTeam(team.href && team.href !== '#' ? route(team.href) : '')"
          >
            <li
              :class="[
                selectedOption.id === team.id ? 'bg-primary-275 text-grayCust-700 font-semibold hover:bg-primary-275' : '',
                'py-2 px-4 cursor-pointer hover:bg-grayCust-100 relative z-0 flex items-center text-sm font-normal gap-2 justify-between'
              ]"
              @click="handleSelection(team)"
            >
              <div class="flex items-center gap-2">
                <div
                  class="size-6 shrink-0 rounded-full bg-grayCust-100 flex items-center justify-center text-grayCust-640"
                >
                  {{ team?.name[0] }}
                </div>
                <span class="block truncate text-grayCust-640 w-[110px]">{{ team.name }}</span>
              </div>
  
              <div class="flex items-center gap-2">
                <OutlineCheckIcon
                  v-if="selectedOption.id === team.id"
                  class="size-4 text-secondary-800"
                />
  
                <inertia-link
                  as="div"
                  :href="route('teams.show', team.id)"
                  @click.stop
                >
                  <OutlineCogIcon class="size-4 text-grayCust-550" />
                </inertia-link>
              </div>
            </li>
          </div>
        </div>
      </div>
    </template>
  </CPopover>
</template>

<script>
import CButton from '@/app/Common/CommonButton.vue';
import CPopover from '@/app/Common/CommonPopover.vue';
import { useAppStore } from '@/store/index';
import { router, usePage } from '@inertiajs/vue3';
import { wTrans } from 'laravel-vue-i18n';
import { mapState, mapStores } from 'pinia';

export default {
  name: "MyTeamList",
  components: {
    CPopover,
    CButton
  },
  props: {
    myTeam: {
      type: Object,
      required: true,
      default: () => ({
        heading: "My Teams",
        teams: [],
      }),
    },
    selectedOption: {
      type: Object,
      required: true,
    },
    justify: {
      type: String,
      required: false,
      default: 'justify-start'
    },
    iconType: {
      type: String,
      required: false,
      default: 'OutlineIcons'
    },
    iconName: {
      type: String,
      required: false,
      default: 'HomeIcon'
    },
    iconSize: {
      type: String,
      required: false,
      default: 'size-4'
    },
    avatarImage: {
      type: String,
      required: false,
      default: ''
    },
    avatarIcon: {
      type: String,
      required: false,
      default: ''
    },
    extraClass: {
      type: String,
      required: false,
      default: ''
    },

  },
  emits: ["select"],
  computed: {
    myTeams() {
      const user = this.$page.props.auth.user;
      if (Object.keys(user)) {
        return user.all_teams.filter(function (e) { return e.user_id == user.id })
      }
      return []
    },
    page() {
      return usePage();
    },
    sharedWithMeTeams() {
      const user = this.$page.props.auth.user;
      if (Object.keys(user)) {
        return user.all_teams.filter(function (e) { return e.user_id != user.id })
      }
      return []
    },
    ...mapStores(useAppStore),
    ...mapState(useAppStore, [
      "user",
      "userActivePlan"
    ]),
  },
  methods: {

   showUpgradeWarning(feature)  {
    const msg = {
        subHeading: wTrans('teams_not_allow'),
        planMessage: this.appStore.featureAvailableFrom[feature] ? wTrans('this_feature_is_available_in_plan_and_above', { planName: this.appStore.featureAvailableFrom[feature] }) : null,
        feature,
        triggerRef: `create_team_header_dropdown_${feature}`
    };
      this.appStore.setUpgradeWarning(msg);
    },
    navigateToTeam(href) {
      href && router.visit(href)
    },
    handleSelection(team) {
      window.location = route("current-team.update")+"?team_id="+team.id;
    },
  },
};

</script>