<template>
  <div 
    class="h-full border-r sidebar-width border-grayCust-160 transition-all duration-300 ease-in-out" 
    :class="[
      isCollapsed ? 'w-14' : 'w-[240px]',
      bgColor
    ]"
  >
    <div class="h-full flex flex-col">
      <div class="flex-1 overflow-y-auto custom-scrollbar-v2">
        <nav class="px-2 py-4">
          <ul class="space-y-1">
            <template
              v-for="(item, index) in sidebarItems"
              :key="index"
            >
              <li 
                v-if="!item?.hide" 
                class="relative"
              >
                <SidebarMenuItemContent
                  v-if="item.icon || item.image"
                  :item="item"
                  :href="item.children && item.children.length ? '#' : route(item.path, item.params || {})"
                  :is-collapsed="isCollapsed"
                  :is-selected="item.is_active"
                  :bg-color="bgColor"
                  @click="(event) => handleItemClick(event, item, index)"
                />
                <div
                  v-else
                  class="text-xs uppercase text-grayCust-500 font-semibold py-3"
                >
                  {{ item.title }}
                </div>
                
                <!-- Regular Accordion for Expanded State -->
                <transition 
                  name="accordion"
                  @before-enter="beforeEnter" 
                  @enter="enter" 
                  @leave="leave"
                >
                  <div
                    v-if="!isCollapsed && item.children && openItems.has(index)"
                    ref="accordionRefs"
                    class="mt-1 space-y-1 overflow-hidden"
                  >
                    <div
                      v-for="(child, childIndex) in item.children"
                      :key="childIndex"
                    >
                      <SidebarMenuItemContent
                        :item="child"
                        :href="route(child.path, child.params || {} )"
                        :is-selected="child.is_active"
                        :is-collapsed="isCollapsed"
                        :bg-color="bgColor"
                        @click="handleChildItemClick(child, index)"
                      />
                    </div>
                  </div>
                </transition>
              </li>
            </template>
          </ul>
        </nav>
      </div>
    </div>
    
    <transition name="menu-fade">
      <div 
        v-if="isCollapsed && clickedItem !== null && clickedMenuData && clickedMenuData.children?.length"
        ref="clickMenuRef"
        class="fixed z-40 w-48 rounded-md shadow-lg bg-white border border-grayCust-160 py-1"
        :style="{
          top: `${menuPosition.top}px`,
          left: `${menuPosition.left}px`
        }"
      >
        <div class="px-3 py-2 text-sm font-medium border-b border-grayCust-160">
          {{ clickedMenuData.title }}
        </div>
        <div class="py-1 px-2 space-y-1">
          <a 
            v-for="(child, childIndex) in clickedMenuData.children" 
            :key="childIndex"
            :href="route(child.path, child.params || {})"
            class="block px-4 py-1.5 text-sm text-grayCust-700 hover:bg-grayCust-50 rounded-md transition-colors duration-200"
            :class="{'bg-grayCust-50 text-grayCust-800 font-medium border border-grayCust-160': child.is_active}"
            @click="closeMenu"
          >
            {{ child.title }}
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import SidebarMenuItemContent from '@/app/Common/Sidebar/CommonSidebarMenuItems.vue';
import { router } from '@inertiajs/vue3';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watchEffect } from 'vue';
import { route } from 'ziggy-js';

const props = defineProps({
    menuItems: {
      type: Array,
      required: true
    },
    isCollapsed: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: 'bg-grayCust-50'
    },
    routeParams: {
      type: Object,
      default: () => ({}),
    },
  });

const emit = defineEmits(['on-handle-click']);

const currentRoute = ref(route().current());
const clickedItem = ref(null);
const clickedMenuData = ref(null);
const menuPosition = ref({ top: 0, left: 0 });
const clickMenuRef = ref(null);
const openItems = ref(new Set());

router.on('navigate', () => {
  currentRoute.value = route().current();
});

const sidebarItems = computed(() => {
  return props.menuItems.map(item => {
    item.is_active = (item?.path == currentRoute.value) || (item?.routename?.includes(currentRoute.value));
    item.hide = item.hide || false;
    item.children?.forEach(child => {
      child.is_active = (child.path == currentRoute.value);
    });
    return item;
  });
});

// function onHandleClick(item, index, is_parent = true) {
//   if (!is_parent || !item.children?.length) return;

//   if (openItems.value.has(index)) {
//     openItems.value.delete(index);
//   } else {
//     openItems.value.add(index);
//   }
//   emit('on-handle-click');
// }

function handleItemClick(event, item, index) {
  
  if (event && event.preventDefault) {
    event.preventDefault();
  }
  
  // If the item has children, toggle the accordion regardless of screen size
  if (item.children && item.children.length) {
    
    if (openItems.value.has(index)) {
      openItems.value.delete(index);
    } else {
      openItems.value.add(index);
    }
    
    
    if (props.isCollapsed) {
      
      if (clickedItem.value === index) {
        closeMenu();
      } else {
        
        clickedItem.value = index;
        clickedMenuData.value = item;
        
        
        if (event && event.currentTarget) {
          const rect = event.currentTarget.getBoundingClientRect();
          menuPosition.value = {
            top: rect.top + 50,
            left: rect.right + 8 
          };
        } else {
          
          const sidebarElement = document.querySelector('.sidebar-width');
          if (sidebarElement) {
            const rect = sidebarElement.getBoundingClientRect();
            menuPosition.value = {
              top: index * 57, 
              left: rect.right + 8
            };
          }
        }
      }
    }
    
    
    return;
  } 
  
  if (item.path) {
    router.visit(route(item.path, item.params || {}));    
    emit('on-handle-click');
  }
}

function handleChildItemClick(child) {
  if (child.path) {
    router.visit(route(child.path, child.params || {}));
  }
    emit('on-handle-click');
}

function closeMenu() {
  clickedItem.value = null;
  clickedMenuData.value = null;
}


function handleOutsideClick(event) {
  if (clickedItem.value !== null) {
    const menuElement = clickMenuRef.value;
    const sidebarElement = document.querySelector('.sidebar-width');
    
    if ((!menuElement || !menuElement.contains(event.target)) && 
        (!sidebarElement || !sidebarElement.contains(event.target))) {
      closeMenu();
    }
  }
}

onMounted(() => {
  document.addEventListener('click', handleOutsideClick);
  sidebarItems.value.forEach((item, index) => {
    if (item.children?.some(child => child.is_active)) {
      openItems.value.add(index);
    }
  });
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleOutsideClick);
});

watchEffect(() => {
  sidebarItems.value.forEach((item, index) => {
    if (item.children?.some(child => child.is_active)) {
      openItems.value.add(index);
    }
  });
});

function beforeEnter(el) {
  el.style.maxHeight = '0px';
}
function enter(el) {
  nextTick(() => {
    el.style.maxHeight = el.scrollHeight + 'px';
    el.style.transition = 'max-height 0.3s ease-in-out';
  });
}
function leave(el) {
  el.style.maxHeight = '0px';
  el.style.transition = 'max-height 0.3s ease-in-out';
}
</script>

<style scoped>
.sidebar-width {
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.accordion-enter-active, .accordion-leave-active {
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}

.accordion-enter-from, .accordion-leave-to {
  max-height: 0;
}

/* Transition for the popup menu */
.menu-fade-enter-active,
.menu-fade-leave-active {
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.menu-fade-enter-from,
.menu-fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>