<template>
  <DialogModal
    :show="show"
    max-width="lg"
    footer-color="bg-white"
    extra-flex="flex justify-center items-center"
    BlurClass="opacity-class"
    header-class="modal-padding"
    main-class="content-padding"
    extra-class="site-modal relative custom-scroll"
    @close="closePaidUpgradeModal"
  >
    <template #content>
      <div class="modal-height">
        <div
          class="absolute right-7 top-7 cursor-pointer"
          @click="closePaidUpgradeModal"
        >
          <img
            :src="cdn(`images/UpgradeModal/close-icon.svg`)"
            alt=""
          >
        </div>
        <div class="custom-bg pt-10">
          <img
            :src="cdn(`images/UpgradeUserModal/site-img.svg`)"
            class="mx-auto mb-8 w-96"
            alt=""
          >
          <div class="text-center text-2xl font-bold text-grayCust-430">
            {{ $t('account_upgraded') }}
          </div>
        </div>
        <div class="p-6">
          <div class="flex items-start justify-between">
            <div class="flex">
              <img
                :src="cdn(`images/UpgradeUserModal/pro-star-icon.png`)"
                alt=""
                class="mr-3"
              >
              <div>
                <div class="text-sm font-medium text-grayCust-910">
                  {{ $t('new_plan') }}
                </div>
                <div class="text-lg font-semibold text-grayCust-740">
                  {{ planName }} {{ $t('plan') }}
                </div>
              </div>
            </div>
            <div class="flex items-end">
              <div class="text-sm font-normal text-grayCust-910">
                <span class="text-2xl font-bold text-grayCust-740">
                  ${{ price }}
                </span>
                /{{ interval }}
              </div>
            </div>
          </div>
          <div class="item-center list-bg my-4 flex flex-wrap justify-center px-4 py-2 lg:flex-nowrap">
            <div class="flex flex-wrap items-center lg:flex-nowrap">
              <div class="mb-1 mr-4 flex items-center lg:mb-0">
                <img
                  :src="cdn(`images/UpgradeUserModal/level-icon.svg`)"
                  alt=""
                  class="mr-1"
                >
                <div class="text-sm font-medium text-grayCust-910">
                  {{ $t(feature?.name) + ' ' + (feature?.type == 'numeric' ? $t('limit') : '') + ':' }}
                  <span class="ml-2 text-sm font-semibold text-grayCust-740">
                    {{ featureValue }}
                  </span>
                </div>
              </div>
              <div class="m-0 flex items-center lg:ml-4">
                <div class="flex items-center">
                  <img
                    :src="cdn(`images/UpgradeUserModal/calendar-icon.svg`)"
                    alt=""
                    class="mr-1"
                  >
                  <div class="text-sm font-medium text-grayCust-910">
                    {{ $t('expiry') }}:
                    <span class="ml-2 text-sm font-semibold text-grayCust-740">
                      {{ expiry }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="flex flex-wrap items-center justify-center">
              <div
                v-for="item in featureList"
                :key="item.id"
                class="mb-3 inline-flex w-1/3 items-center pr-2 text-sm font-normal text-grayCust-910"
              >
                <span class="mr-3">&bull;</span>
                {{ $t(item.upgradeModalText, item.upgradeModalTextValues) }}
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-4 bg-grayCust-50 px-6 py-5 lg:grid-cols-2">
          <button
            class="inline-flex items-center justify-center rounded-lg border border-grayCust-160 bg-white px-4 py-2.5 text-sm font-semibold text-grayCust-1740 focus:ring-2 focus:ring-primary-900 focus:ring-offset-2"
            @click="closePaidUpgradeModal"
          >
            <!-- <svg class="mr-2" width="25" height="24" viewBox="0 0 25 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M6.58913 13.2799H9.67913V20.4799C9.67913 22.1599 10.5891 22.4999 11.6991 21.2399L19.2691 12.6399C20.1991 11.5899 19.8091 10.7199 18.3991 10.7199H15.3091V3.5199C15.3091 1.8399 14.3991 1.4999 13.2891 2.7599L5.71913 11.3599C4.79913 12.4199 5.18913 13.2799 6.58913 13.2799Z"
                        stroke="#000" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                  </svg> -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="mr-2 size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            {{ $t('close') }}
          </button>
          <a 
            :href="route('subscriptions.index')"
            class="inline-flex items-center justify-center rounded-lg border border-grayCust-160 bg-primary-900 px-4 py-2.5 text-sm font-semibold text-white focus:ring-2 focus:ring-primary-900 focus:ring-offset-2"
          >
            <MonthlyPlanIcon
              class="mr-2"
              width="24"
              height="24"
              fill="#fff"
              aria-hidden="true"
            />
            <!-- <svg class="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M22 6V8.42C22 10 21 11 19.42 11H16V4.01C16 2.9 16.91 2 18.02 2C19.11 2.01 20.11 2.45 20.83 3.17C21.55 3.9 22 4.9 22 6Z"
                        stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                     <path
                        d="M2 7V21C2 21.83 2.93998 22.3 3.59998 21.8L5.31 20.52C5.71 20.22 6.27 20.26 6.63 20.62L8.28998 22.29C8.67998 22.68 9.32002 22.68 9.71002 22.29L11.39 20.61C11.74 20.26 12.3 20.22 12.69 20.52L14.4 21.8C15.06 22.29 16 21.82 16 21V4C16 2.9 16.9 2 18 2H7H6C3 2 2 3.79 2 6V7Z"
                        stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                        stroke-linejoin="round" />
                     <path d="M6 9H12" stroke="#fff" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                     <path d="M6.75 13H11.25" stroke="#fff" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                  </svg> -->
            {{ $t('view_subscription') }}
          </a>
        </div>
      </div>
    </template>
  </DialogModal>
</template>
 
<script>
import DialogModal from "@/Jetstream/DialogModal.vue";

import MonthlyPlanIcon from "@/components/UpdatedDesignVersion/ImageComponents/Profile/MonthlyPlanIcon.vue";
import { useAppStore } from '@/store/index';
import { mapState, mapStores } from 'pinia';

export default {
    
    name: "PaymentSuccessful",
    components: {
        DialogModal,
        MonthlyPlanIcon
    },
    computed: {
        show() {
            const obj = this.appStore.paymentSuccessModal;
            return obj && Object.keys(obj).length !== 0 && obj.constructor === Object;
        },
        ...mapStores(useAppStore),
        ...mapState(useAppStore, ["userBaseActivePlan", "plans"]),
        featureList() {
            if (this.show) {
                const temp = [...this.appStore.paymentSuccessModal.featureList]
                temp.pop()
                return temp
            }
        },
        price() {
            if (this.show) {
                return this.appStore.paymentSuccessModal.price
            }
        },
        planName() {
            if (this.show) {
                return this.appStore.paymentSuccessModal.planName
            }
        },
        interval() {
            if (this.show) {
                return this.appStore.paymentSuccessModal.interval
            }
        },
        feature() {
          console.log(this.appStore.paymentSuccessModal.feature);
            if (this.show) {
                return this.appStore.paymentSuccessModal.feature
            }
        },
        featureValue() {
            if (this.show) {
                return this.appStore.paymentSuccessModal.featureValue
            }
        },
        expiry() {
            if (this.show) {
                return this.appStore.paymentSuccessModal.expiry
            }
        },
    },
    methods: {
        closePaidUpgradeModal() {
            window.location.reload();
        },
    }
}
</script>
<style scoped>
.btn-height {
   height: calc(100vh - 30px);
}

.custom-bg {
   background-image: url('@public/images/UpgradeUserModal/custom-bg.svg'), linear-gradient(135deg, #E7FFF7 0%, #FDFBEE 100%);
   height: 350px;
   background-repeat: no-repeat;
   background-size: cover;
}

.list-bg {
   background: radial-gradient(12075.09% 50.52% at 50.00% 50.00%, #F2F4F7 0%, rgba(242, 244, 247, 0.00) 100%);
}



@media screen and (max-width:991px) {
   .custom-bg {
      height: unset;
   }

   .modal-height {
      height: 700px;
      overflow: auto;
   }
}



@media screen and (max-width:375px) and (max-height:700px) {
   .modal-height {
      max-height: 604px;
      overflow: auto;
   }
}

@media screen and (max-width:320px) and (max-height:500px) {
   .modal-height {
      max-height: 446px;
      overflow: auto;
   }
}
</style>
