<template>
  <div
    v-if="showBanner"
    class="p-1 bg-gradient-free-to-paid"
  >
    <div class="bg-white/80 backdrop-blur-sm sm2:px-4 py-2 px-2 custom-shadow">
      <div class="flex gap-1.5 justify-center items-center">
        <div class="">
          <InformationCircleIcon
            class="size-5 text-primary-900"
            aria-hidden="true"
          />
        </div>
        <div class="flex items-center gap-2">
          <p class="sm2:text-sm text-xs font-medium text-primary-900">
            You are on the Free plan
          </p>
          <CButton
            btn-title="Upgrade Now"
            btn-type="primary-text-btn"
            icon-name="ArrowRightIcon"
            icon-position="last"
            title-class="underline decoration-primary-900 font-semibold sm2:text-sm text-xs"
            @click="upgradeNow"
          />
          <span class="h-3 w-px bg-primary-200/30" />
          <CButton
            btn-title="Dismiss for 7 days"
            btn-type="gray-text-btn"
            icon-name="XIcon"
            icon-position="last"
            title-class="font-semibold"
            class="hidden lg:flex"
            @click="dismissFor7Days"
          />
          <AppIconButton
            class="lg:hidden flex !border-none !h-fit !w-fit"
            icon-name="OutlineXIcon"
            btn-type="gray-text-btn"
            @click="dismissFor7Days"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CButton from '@/components/New/Blocks/Buttons/CommonButton.vue';
import { InformationCircleIcon } from "@heroicons/vue/solid";
export default {
  components: {
    InformationCircleIcon,
    CButton,
  },
  data() {
    return {
      showBanner: true,
    };
  },
  mounted() {
    this.checkBannerVisibility();
  },
  methods: {
    dismissFor7Days() {
      const currentTime = new Date().getTime();
      const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;

      // Calculate the dismissal timestamp and store it in localStorage
      const dismissalTimestamp = currentTime + sevenDaysInMs;
      localStorage.setItem("freeToPaidBanner_"+this.$page.props.auth?.user?.id, JSON.stringify(dismissalTimestamp));


      // Hide the banner
      this.showBanner = false;
    },
    checkBannerVisibility() {
      const freeToPaidBanner = localStorage.getItem("freeToPaidBanner_"+this.$page.props.auth?.user?.id);
      if (freeToPaidBanner) {
        const expirationTime = JSON.parse(freeToPaidBanner);
        const currentTime = new Date().getTime();

        // Determine visibility
        this.showBanner = currentTime >= expirationTime;
      } else {
        this.showBanner = true;
      }
    },
    upgradeNow() {
      this.$inertia.get('/subscription/plans?trigger_ref=topbar_notice_upgrade_now')
    },
  },
};
</script>

<style scoped>
.bg-gradient-free-to-paid {
  background: linear-gradient(90deg, rgba(243, 233, 141, 0.5) 0%, rgba(249, 250, 251, 1) 32%, rgba(249, 250, 251, 1) 72%, rgba(243, 233, 141, 0.5) 85%, rgba(251, 126, 191, 0.5) 100%);
}

.custom-shadow {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
</style>