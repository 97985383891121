export default {
  mounted(el, binding) {
    const tooltipText = binding.value?.text || binding.value;
    const position = binding.value?.position || 'top';

    if (!tooltipText) return;

    // Create tooltip elements but don't add to DOM yet
    const tooltip = document.createElement('div');
    tooltip.className = 'custom-tooltip';
    
    // Create content container to hold text
    const tooltipContent = document.createElement('div');
    tooltipContent.innerText = tooltipText;
    tooltip.appendChild(tooltipContent);

    const tooltipArrow = document.createElement('div');
    tooltipArrow.className = 'custom-tooltip-arrow';
    tooltip.appendChild(tooltipArrow);

    // Style tooltip (hidden initially)
    Object.assign(tooltip.style, {
      position: 'fixed', // Use fixed positioning to avoid scroll issues
      backgroundColor: 'black',
      color: 'white',
      padding: '6px 10px',
      borderRadius: '6px',
      fontSize: '12px',
      pointerEvents: 'none',
      opacity: '0',
      transition: 'opacity 0.2s',
      maxWidth: '250px',
      width: 'auto',
      wordBreak: 'break-all',
      fontWeight: '600',
      zIndex: '99999999',
      display: 'none' // Start with display none
    });

    // Pre-configure arrow styles based on position
    if (position === 'left') {
      Object.assign(tooltipArrow.style, {
        position: 'absolute',
        top: '50%',
        left: '100%',
        transform: 'translateY(-50%)',
        borderLeft: '5px solid black',
        borderTop: '5px solid transparent',
        borderBottom: '5px solid transparent',
        borderRight: 'none'
      });
    } else {
      Object.assign(tooltipArrow.style, {
        position: 'absolute',
        bottom: '-5px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '0',
        height: '0',
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderTop: '5px solid black'
      });
    }

    // Add tooltip to body
    document.body.appendChild(tooltip);

    // Function to position and show tooltip
    const showTooltip = () => {
      // First make it visible but transparent for measurement
      tooltip.style.display = 'block';
      tooltip.style.opacity = '0';
      
      // Get element position
      const rect = el.getBoundingClientRect();
      
      // Calculate tooltip dimensions after it's visible
      const tooltipRect = tooltip.getBoundingClientRect();
      
      // Calculate position based on element and tooltip dimensions
      let top, left;
      
      if (position === 'left') {
        top = rect.top + (rect.height / 2) - (tooltipRect.height / 2);
        left = rect.left - tooltipRect.width - 10;
      } else { // top
        top = rect.top - tooltipRect.height - 10;
        left = rect.left + (rect.width / 2) - (tooltipRect.width / 2);
      }
      
      // Apply position
      tooltip.style.top = `${top}px`;
      tooltip.style.left = `${left}px`;
      
      // Make visible with transition
      requestAnimationFrame(() => {
        tooltip.style.opacity = '1';
      });
    };

    // Function to hide tooltip
    const hideTooltip = () => {
      tooltip.style.opacity = '0';
      
      // Wait for transition to complete before hiding
      setTimeout(() => {
        tooltip.style.display = 'none';
      }, 200);
    };

    // Store functions for cleanup
    el._tooltipInstance = {
      tooltip,
      tooltipContent,
      showTooltip,
      hideTooltip,
      updateText: (newText) => {
        tooltipContent.innerText = newText;
      }
    };

    // Add event listeners
    el.addEventListener('mouseenter', showTooltip);
    el.addEventListener('mouseleave', hideTooltip);
  },

  updated(el, binding) {
    if (!el._tooltipInstance) return;
    
    const newText = binding.value?.text || binding.value;
    if (newText) {
      el._tooltipInstance.updateText(newText);
    }
  },

  unmounted(el) {
    if (!el._tooltipInstance) return;
    
    const { tooltip, showTooltip, hideTooltip } = el._tooltipInstance;
    
    // Remove event listeners
    el.removeEventListener('mouseenter', showTooltip);
    el.removeEventListener('mouseleave', hideTooltip);
    
    // Remove tooltip from DOM
    if (tooltip && tooltip.parentNode) {
      tooltip.parentNode.removeChild(tooltip);
    }
    
    // Clean up reference
    delete el._tooltipInstance;
  }
};
